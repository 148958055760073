import { useState, useEffect } from "react";
import { connect } from "react-redux";

import config from "../../config";

import {
	Box,
	Button,
	ColumnLayout,
	Container,
	Header,
	SpaceBetween,
	StatusIndicator,
	ButtonDropdown,
	CopyToClipboard,
	Icon,
} from "@cloudscape-design/components";

import AddAccountModal from "./AddAccountModal";

const AccountsOverview = ({ token, title }) => {
	const [accounts, setAccounts] = useState([]);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		fetch(`${config.api_endpoint}/accounts`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => setAccounts(json["accounts"]))
			.catch((error) => console.error(error));
	}, [token]);

	const deleteAccount = async (key) => {
		let items = [...accounts];

		fetch(`${config.api_endpoint}/accounts/${items[key]["AccountID"]}`, {
			mode: "cors",
			method: "DELETE",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => setAccounts(json["accounts"]))
			.catch((error) => console.error(error));
	};

	const getStatusIndicator = (status) => {
		switch (status) {
			case "Available":
				return "success";
			case "Leased":
				return "in-progress";
			case "Deleting":
			case "Cleaning In Progress":
				return "loading";
			case "Dirty":
				return "warning";
			case "Cleanup Error":
				return "error";
			default:
				return "info";
		}
	};

	const ValueWithLabel = ({ label, children }) => (
		<div>
			<Box variant='awsui-key-label'>{label}</Box>
			<div>{children}</div>
		</div>
	);

	return (
		<Container
			header={
				<Header
					variant='h3'
					actions={
						<SpaceBetween direction='horizontal' size='xs'>
							<Button
								onClick={() => {
									setVisible(true);
								}}
							>
								Add New Account <Icon name='add-plus' />
							</Button>
						</SpaceBetween>
					}
				>
					{title} ({accounts.length})
				</Header>
			}
		>
			<AddAccountModal
				visible={visible}
				setVisible={setVisible}
				accounts={accounts}
				setAccounts={setAccounts}
			/>

			<SpaceBetween size='s'>
				{accounts.map((account, key) => (
					<Container key={key}>
						<b>AWS Account ID: {account["AccountID"]}</b>{" "}
						<CopyToClipboard
							copyButtonText='Copy'
							copyErrorText='ID failed to copy'
							copySuccessText='ID copied'
							textToCopy={account["accountID"]}
							variant='inline'
						/>
						<p />
						<ColumnLayout columns={6} variant='text-grid' minColumnWidth='100'>
							<ValueWithLabel label='Account Status'>
								<StatusIndicator
									type={getStatusIndicator(account["Status"])}
								>
									{account["Status"]}
								</StatusIndicator>
							</ValueWithLabel>

							<ValueWithLabel label='Description'>
								{account["Description"]}
							</ValueWithLabel>

							<ValueWithLabel label='Leased To'>
								{account.LeaseID}
							</ValueWithLabel>

							<ButtonDropdown
								onItemClick={() => {
									deleteAccount(key);
								}}
								items={[
									{
										text: "Delete",
										id: "rm",
										disabled: false,
									},
									// { text: "Move", id: "mv", disabled: false },
									// { text: "Rename", id: "rn", disabled: true },
								]}
								disabled={account["Status"] === "Deleting"}
								loading={account["Status"] === "Deleting"}
							>
								{account["Status"] === "Deleting"
									? "Deleting"
									: "Actions"}
							</ButtonDropdown>
						</ColumnLayout>
					</Container>
				))}
			</SpaceBetween>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(AccountsOverview);
