import { connect } from "react-redux";
import { useState } from "react";
import {
	ContentLayout,
	SideNavigation,
	AppLayout,
	TopNavigation,
	Header,
} from "@cloudscape-design/components";

import { I18nProvider } from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";

const LOCALE = "en";

const RSHAppLayout = (props) => {
	const [navOpen, setNavOpen] = useState([]);

	props = {
		...{
			show_breadcrumbs: true,
			nav_open: true,
			show_side_nav: true,
			page_title: "Rebura Sandbox Hub",
		},
		...props,
	};

	let navItems = [
		{ type: "link", text: `Leases`, href: `/` }
	]

	let util_list = [];

	if (props.profile !== null) {
		util_list.push({
			type: "menu-dropdown",
			text: props.profile.fullname,
			description: props.profile.email,
			iconName: "user-profile",
			items: [{ type: "link", text: "Sign out", href: "/logout" }],
		});

		console.log(props.profile)

		// if (props.profile.is_admin === "Admin") {
		navItems.push({
			type: "link",
			text: `Accounts`,
			href: `/accounts`,
		})
		navItems.push(
		{
			type: "link",
			text: `Settings`,
			href: `/settings`,
		})
		// }
	}

	const navToggle = (e) => {
		setNavOpen(!navOpen);
	};

	return (
		<I18nProvider locale={LOCALE} messages={[messages]}>
			<TopNavigation
				identity={{
					href: "#",
					title: "Rebura Sandbox Hub",
				}}
				utilities={util_list}
			/>

			<AppLayout
				title={props.page_title}
				toolsHide={true}
				onNavigationChange={navToggle}
				navigationOpen={navOpen}
				navigation={
					props.show_side_nav ? (
						<SideNavigation
							header={{
								href: "#",
								text: "Navigation",
							}}
							items={navItems}
						/>
					) : null
				}
				content={
					<ContentLayout
						header={
							<Header variant='h1'>{props.page_title}</Header>
						}
					>
						{props.children}
					</ContentLayout>
				}
			/>
		</I18nProvider>
	);
};

const mapStateToProps = (state) => ({
	profile: state.profile
});

export default connect(mapStateToProps)(RSHAppLayout);
