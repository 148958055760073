import { connect } from "react-redux";

import { SpaceBetween, Alert } from "@cloudscape-design/components";

import LeaseList from "./LeaseList";

const LeaseOverview = ({ token }) => {
	return (

		<SpaceBetween size='s'>
		<Alert
	      statusIconAriaLabel="Info"
	      header="Welcome to Rebura Sandbox Hub!"
	    >
	      Rebura Sandbox Hub has been installed in your AWS Control Tower to provide sandbox accounts for AWS development.

	      <br />
	      <br />

	      A lease is a temporary reservation of an AWS account. For the duration of your lease, it is soley yours to utilise and test new deployments.
	      When your lease ends, either by you or when it expires, all resources and data in the account will be purged.

	      <br />
	      <br />
	      Here you will see a list of all leases, current and past. Archived leases are permenantly hidden.
	    </Alert>

			<LeaseList
				title='Active Leases'
				filter='Active,Deleting'
				show_create={true}
				hide_empty={false}
			/>

			<LeaseList
				title='Inactive Leases'
				filter='!Active,Archived,Deleting'
				show_create={false}
				hide_empty={true}
			/>
		</SpaceBetween>
	);
};

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(LeaseOverview);
