import { useCallback } from "react";
import { useDispatch, connect } from "react-redux";
import { CLEAR_ALL } from "../../oauth/util/actionTypes";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import Leases from "../pages/Leases";
import Accounts from "../pages/Accounts";
import Settings from "../pages/Settings";

import Auth from "../auth/Auth";
import Login from "../auth/Login";
import Logout from "../auth/Logout";
import LoginError from "../auth/LoginError";

import AuthVerify from "../auth/AuthVerify";

const Router = ({ profile }) => {
	const dispatch = useDispatch();

	const logout = useCallback(() => {
		dispatch({ type: CLEAR_ALL });
		window.location = "/login";
	}, [dispatch]);

	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' element={<Leases />} />

				<Route
					exact
					path='/accounts'
					element={
						<ProtectedRoute
							profile={profile}
							restrict_to_group='CloudOps'
						/>
					}
				>
					<Route exact path='/accounts' element={<Accounts />} />
				</Route>

				<Route
					exact
					path='/settings'
					element={
						<ProtectedRoute
							profile={profile}
							restrict_to_group='CloudOps'
						/>
					}
				>
					<Route exact path='/settings' element={<Settings />} />
				</Route>

				<Route exact path='/logout' element={<Logout />} />
				<Route exact path='/login' element={<Login />} />
				<Route exect path='/auth' element={<Auth />} />
				<Route exect path='/login-error' element={<LoginError />} />
			</Routes>

			<AuthVerify logout={logout} />
		</BrowserRouter>
	);
};

const mapStateToProps = (state) => {
	return {
		profile: state.profile,
	};
};

export default connect(mapStateToProps)(Router);
